import React , { useState,useEffect }from 'react'
import Footer from '../Components/Footer/Footer'
import Menu from '../Components/Navbar/Navbar'
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select,{components} from 'react-select';
import Alert from 'react-bootstrap/Alert';
import {Button} from 'react-bootstrap';
import { FaCaretDown,FaInfoCircle } from "react-icons/fa";
import EstimateTemplateTable from '../Components/DocsTable/EstimateTemplateTable';
import InterchangeableText from '../constants/InterchangeableText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import config from '../aws-exports';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { elasticSearchCreateDocOrTemp, getTemplate, setEsDownFlagTemp, getAssignDefaultTemplates, elasticSearchFetchTemp, elasticSearchDeleteDocOrTemp, uploadTemplate, createAllUserTempsOnElasticsearch, getTemplatesByClientID, getTemplatesByClientIDx, getTemplatesByTeamID, getTemplatesByTeamIDElasticsearch, getEstimateTemplates, getWOTemplates} from '../graphql/queries';
import * as queryString from "query-string";
import {Link, navigate} from 'gatsby';
import Modal from 'react-bootstrap/Modal';
import Loader from './../assets/loading.gif';
import { Helmet } from 'react-helmet';
import ToastBox from '../Components/Toast/Toast';
import  secureLocalStorage  from  "react-secure-storage";
Amplify.configure(config);

const CaretDownIcon = () => {
    return <FaCaretDown color={'#000'} />;
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

export default function Templates(props) {
    const {consoleLog} = require('../Components/commonFunctions.js');
    consoleLog("Templates props: ", props);
    const url_params = queryString.parse(props.location.search);
    consoleLog("Templates url_params: ", url_params );
    consoleLog("Templates url_params.doc_type_id : ", url_params.doc_type_id )
    const [templates, setTemplates] = useState([]);
    const [allTemplates, setAllTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    consoleLog('x>>>',process.env.GATSBY_DEFAULT_LIST_SIZE);
    const [itemsPerPage, setItemsPerPage] = React.useState(process.env.GATSBY_DEFAULT_LIST_SIZE ? process.env.GATSBY_DEFAULT_LIST_SIZE : 10);
    
    const [selectedValue, setSelectedValue] = useState(url_params.doc_type_id);
    const [pageOffset, setPageOffset] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [files, setFiles] = useState("");
    const [show, setShow] = useState(false);
    const [showType, setShowType] = useState('');
    const [uploadError, setUploadError] = useState('');
    const [aDTemplates, setaDTemplates] = useState([]);

    const [teamRole, setTeamRole] = useState('');
    const [userID, setUserID] = useState(0);

    const [forcedPage, setForcedPage] = useState(null);
    const [inputSearch, setInputSearch] = useState("");

    async function fetchTemplates() {
        consoleLog("fetchTemplates");
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let team_role = JSON.parse(secureLocalStorage.getItem('client_data')).team_role;
        let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;
        let client_email = JSON.parse(secureLocalStorage.getItem('client_data')).email;

        consoleLog("fetchTemplates team", team_role);
        if ((team_role == '' || team_role == null) && client_id == leader_id) {
            setTeamRole('Admin');
            setUserID(client_id);
            consoleLog('if1 Team Role');
        }
        if(team_role != '' && team_role != null){
            setTeamRole(team_role);
            setUserID(client_id);
            consoleLog('if2 Team_Role');
        }

        let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.temps;
        consoleLog('fetchTemplates es_down_docs>>>', es_down_docs);
        if(es_down_docs.length > 0){
            for(let i=0; i<es_down_docs.length; i++){
                if(typeof es_down_docs[i].es_down_flag != 'undefined' && es_down_docs[i].es_down_flag == '3'){ //3 for delete
                    consoleLog('fetchTemplates es_down_doc delete>>>', es_down_docs[i]);
                    const es_template_record = await API.graphql({
                        query: elasticSearchFetchTemp,
                        variables: {
                            template_id: es_down_docs[i].template_id
                        }
                    });
                    let template_status = JSON.parse(JSON.parse(es_template_record.data.elasticSearchFetchTemp)).status;
                    consoleLog('fetchTemplates es_down_doc template_status>>>', template_status);
                    if(template_status == 500){
                        break;
                    }
                    const deleteTempElastic = await API.graphql({ // delete from elasticsearch
                        query: elasticSearchDeleteDocOrTemp,
                        variables: {
                          id: es_down_docs[i].template_id,
                          index: 'prodocs-templates'
                        }
                    });
                    consoleLog('fetchTemplates es_down_doc delete_es_doc>>>', deleteTempElastic);

                    let es_down_docx = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.temps;
                    let temp_docs = es_down_docx.filter(d =>d.template_id != es_down_docs[i].template_id);
                    consoleLog('fetchTemplates es_down_doc temp_docs>>>', temp_docs);

                    let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                    consoleLog('fetchTemplates es_down_doc temp_docs_temp>>>', temp_docs_temp);
                    temp_docs_temp.body.temps = temp_docs;
                    consoleLog('fetchTemplates es_down_doc temp_docs_temp final>>>', temp_docs_temp);
                    secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
                }
                if(typeof es_down_docs[i].es_down_flag != 'undefined' && es_down_docs[i].es_down_flag == '2'){ //2 for create
                    const es_template_record = await API.graphql({
                        query: elasticSearchFetchTemp,
                        variables: {
                            template_id: es_down_docs[i].template_id
                        }
                    });
                    let template_status = JSON.parse(JSON.parse(es_template_record.data.elasticSearchFetchTemp)).status;
                    consoleLog('fetchTemplates es_down_doc template_status>>>', template_status);
                    if(template_status == 500){
                        break;
                    }
                    if(template_status == 400){
                        consoleLog('fetchTemplates es_down_doc template_status>>>', template_status);
                        let template_record = await API.graphql({
                            query: getTemplate,
                            variables: {
                                template_id: es_down_docs[i].template_id
                            }
                        });
                        consoleLog('fetchTemplates es_down_doc template_record>>>', template_record);

                        consoleLog("fetchTemplates es_down_doc template_record db: ", template_record.data.getTemplate);
                        template_record = template_record.data.getTemplate;
                        let allData = JSON.parse(template_record.data);
                        consoleLog('fetchTemplates es_down_doc template_record allData:', allData);

                        let temp_for_elastic = allData;
                        temp_for_elastic.template_id = template_record.template_id;
                        temp_for_elastic.doc_type_id = template_record.doc_type_id;
                        temp_for_elastic.user_id = template_record.user_id;
                        temp_for_elastic.team_id = template_record.team_id;
                        temp_for_elastic.shared = template_record.shared;
                        temp_for_elastic.name = template_record.name;
                        temp_for_elastic.date_created = template_record.date_created;
                        temp_for_elastic.date_updated = template_record.date_updated;
                        temp_for_elastic.description = template_record.description;
                        temp_for_elastic.update_user_id = template_record.update_user_id;

                        consoleLog('fetchTemplates es_down_doc template_record temp_for_elastic', temp_for_elastic);

                        let temp_for_elasticx = JSON.stringify(temp_for_elastic);

                        const save_elastic_es = await API.graphql({
                            query: elasticSearchCreateDocOrTemp,
                            variables: {
                                id: es_down_docs[i].template_id,
                                index: 'prodocs-templates',
                                template: temp_for_elasticx
                            }
                        });
                        consoleLog('fetchTemplates es_down_doc template_record save_elastic_es', save_elastic_es);

                        const set_flag_off = API.graphql({
                            query: setEsDownFlagTemp,
                            variables: {
                                template_id: es_down_docs[i].template_id,
                                flag: '0'
                            }
                        });
                        consoleLog('fetchTemplates es_down_doc set_flag_off>>>', set_flag_off);

                        let es_down_docx = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.temps;
                        let temp_docs = es_down_docx.filter(d =>d.template_id != es_down_docs[i].template_id);
                        consoleLog('fetchTemplates es_down_doc temp_docs>>>', temp_docs);

                        let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
                        consoleLog('fetchTemplates es_down_doc temp_docs_temp>>>', temp_docs_temp);
                        temp_docs_temp.body.temps = temp_docs;
                        consoleLog('fetchTemplates es_down_doc temp_docs_temp final>>>', temp_docs_temp);
                        secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
                    }
                }
            }
        }
        
        consoleLog("fetchTemplates client_id: ", client_id);
        consoleLog("fetchTemplates selectedValue: ", selectedValue);
        consoleLog("fetchTemplates itemsPerPage: ", itemsPerPage);
        consoleLog("fetchTemplates pageOffset: ", pageOffset);
        consoleLog("fetchTemplates inputSearch: ", inputSearch);

        let est_templates = [];
        let est_templates_rec = 0;

        const team_estimate_templates = await API.graphql({
            query: getTemplatesByTeamIDElasticsearch,
            variables: {
                team_id: team_id,
                doc_type_id: selectedValue,
                limit: itemsPerPage,
                offset: pageOffset,
                search: inputSearch
            }
        });

        consoleLog('fetchTemplates>',team_estimate_templates);

        est_templates = JSON.parse(team_estimate_templates.data.getTemplatesByTeamIDElasticsearch).data;
        est_templates_rec = JSON.parse(team_estimate_templates.data.getTemplatesByTeamIDElasticsearch).count;
        let est_templates_status = JSON.parse(team_estimate_templates.data.getTemplatesByTeamIDElasticsearch).status;

        consoleLog("fetchTemplates estimate_templates>",est_templates);
        consoleLog("fetchTemplates est_templates_rec>",est_templates_rec);
        consoleLog("fetchTemplates est_templates_status>",est_templates_status);

        let doc_data = [];

        if(est_templates.length < 1){
            const team_estimate_templates = await API.graphql({
                query: getTemplatesByTeamID,
                variables: {
                    team_id: team_id,
                    doc_type_id: selectedValue,
                    limit: itemsPerPage,
                    offset: pageOffset,
                    search: inputSearch
                }
            });
            est_templates = JSON.parse(team_estimate_templates.data.getTemplatesByTeamID).data;
            est_templates_rec = JSON.parse(team_estimate_templates.data.getTemplatesByTeamID).count.count;
            consoleLog('fetchTemplates get from DB...');
            consoleLog("fetchTemplates estimate_templates>",est_templates);
            consoleLog("fetchTemplates est_templates_rec>",est_templates_rec);
            doc_data = est_templates;
            /// since we have got the documents now lets create documents on elasticsearch
            if(doc_data.length > 0 && est_templates_status == 200){
                const create_doc_es = await API.graphql({
                  query: createAllUserTempsOnElasticsearch,
                  variables: {
                    user_id: client_id, //sending client id here to create only users own templates
                    team_id: team_id,
                    doc_type_id: 0,
                    start_date: '',
                    end_date: '',
                    email: client_email
                  },
                });
                consoleLog('fetchTemplates create on ES...',create_doc_es);
            }
        }else{
            doc_data = est_templates.map(function(element){
                return element._source;
            });
            consoleLog('fetchTemplates get from ES...',doc_data);
        }

        setTotalRecords(est_templates_rec);
        setTemplates(doc_data);
        setLoading(false);
    }

    async function handleSearchInput(e){
        consoleLog('handleSearchInput e>', e.target.value)
        setInputSearch(e.target.value);

        setForcedPage(0);

        let est_templates = [];
        let est_templates_rec = 0;

        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        let team_role = JSON.parse(secureLocalStorage.getItem('client_data')).team_role;
        let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        let leader_id = JSON.parse(secureLocalStorage.getItem("client_data")).leader_id;

        consoleLog('handleSearchInput team_role>', team_role);
        
        const team_estimate_templatex = await API.graphql({
            query: getTemplatesByTeamIDElasticsearch,
            variables: {
                team_id: team_id,
                doc_type_id: selectedValue,
                limit: itemsPerPage,
                offset: 0,
                search: e.target.value
            }
        });

        consoleLog('handleSearchInput team_estimate_templatex>',team_estimate_templatex);

        est_templates = JSON.parse(team_estimate_templatex.data.getTemplatesByTeamIDElasticsearch).data;
        est_templates_rec = JSON.parse(team_estimate_templatex.data.getTemplatesByTeamIDElasticsearch).count;

        consoleLog("handleSearchInput est_templates>",est_templates);
        consoleLog("handleSearchInput est_templates_rec>",est_templates_rec);
        
        let doc_data = [];

        if(est_templates.length < 1){
            const team_estimate_templates = await API.graphql({
                query: getTemplatesByTeamID,
                variables: {
                    team_id: team_id,
                    doc_type_id: selectedValue,
                    limit: itemsPerPage,
                    offset: pageOffset,
                    search: e.target.value
                }
            });
            est_templates = JSON.parse(team_estimate_templates.data.getTemplatesByTeamID).data;
            est_templates_rec = JSON.parse(team_estimate_templates.data.getTemplatesByTeamID).count.count;
            consoleLog('handleSearchInput get from DB...');
            consoleLog("handleSearchInput estimate_templates>",est_templates);
            consoleLog("handleSearchInput est_templates_rec>",est_templates_rec);
            doc_data = est_templates;
        }else{
            doc_data = est_templates.map(function(element){
                return element._source;
            });
        }
        setTotalRecords(est_templates_rec);
        setTemplates(doc_data);
    }

    async function getADTemplates(){
        consoleLog('getADTemplates');
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        const d_temps = await API.graphql({
            query: getAssignDefaultTemplates,
            variables:{
                user_id:client_id
            }
        });

        consoleLog('getADTemplates', JSON.parse(d_temps.data.getAssignDefaultTemplates));
        setaDTemplates(JSON.parse(d_temps.data.getAssignDefaultTemplates));
    }

    async function fetchAllTemplates() {
        consoleLog("fetchAllTemplates");
        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        consoleLog("fetchAllTemplates client_id: ", client_id);
        consoleLog("fetchAllTemplates selectedValue: ", selectedValue);

        let my_query = (selectedValue == 1) ? getEstimateTemplates : getWOTemplates;

        consoleLog("fetchAllTemplates my_query: ", my_query);
        const my_templates_result = await API.graphql({
            query: my_query,
            variables: {
                user_id: client_id,
            }
        });

        var my_templates;
        if(selectedValue == 1){
            my_templates = JSON.parse(my_templates_result.data.getEstimateTemplates);
        }
        else{
            my_templates = JSON.parse(my_templates_result.data.getWOTemplates);
        }

        consoleLog("fetchAllTemplates my_templates_result>",my_templates_result);
        consoleLog("fetchAllTemplates my_templates>",my_templates);
  
        setAllTemplates(my_templates);
    }

    const handleChangeSelect = e => {
        setSelectedValue(e.value);
        setForcedPage(0);
        setPageOffset(0);
        if(e.value == 1){
            navigate('/templates?doc_type_id=1');
        }else{
            navigate('/templates?doc_type_id=2');
        }
        consoleLog('selectedIndex e.value>',e.value);
        // fetchTemplates();
    }

    function selectType(type){
        setSelectedValue(type);
        consoleLog('selectType', type);
    }

    const optionsx = [
        { value: 1, label: 'Estimate' },
        { value: 2, label: 'Work Order' }
    ];

    const customeStyles={
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink !important',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
    }

    var page = 'template';

    const validateJSON = (data) => {
        try {
            JSON.parse(data);
        } catch {
            return false;
        }
        return true;
    }


    const handleChangeJSON = e => {
        const reader = new FileReader();
        if(!e.target.files[0])
        {
            setShow(true);
            setUploadError('Please Select a file to upload');
            setShowType('Error')
            return false;
        }

        reader.readAsText(e.target.files[0], "UTF-8");
        reader.onload = e => {
            if (!validateJSON(e.target.result)) {
                setShow(true);
                setUploadError('Please select JSON files only!');
                setShowType('Error');
                return;
            }
            else{
                // setFiles(e.target.result);
                UploadTemp(e.target.result);
            }
            setLoading(true)
        }
    };

    const getDateTime = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
  
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
  
        let date = [year, month, day].join('-');

        let hour = d.getHours();
        let minutes = d.getMinutes();

        if (hour.length < 2)
            hour = '0' + hour;
        if (minutes.length < 2)
            minutes = '0' + minutes;

        let time = [hour, minutes].join(':');
  
        return date+' '+time+':00'; 
    }

    async function UploadTemp(file){

        var client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        var team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        var team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
        let client_email = JSON.parse(secureLocalStorage.getItem('client_data')).email;
        let dateTime = getDateTime();

        consoleLog('dateTime>>>',dateTime);

        const uploadTemplateaa =  await API.graphql({
            query: uploadTemplate,
            variables: {
                user_id: client_id,
                team_id: team_id,
                email:client_email,
                data:file,
                date: dateTime,
            }
        });
        let res = JSON.parse(uploadTemplateaa.data.uploadTemplate);
        res = JSON.parse(res);
        consoleLog("UploadTemp", res);
        setLoading(false);
        if(res.status == 1){
            fetchTemplates();
            setShow(true);
            setUploadError('New Template has been created.');
            setShowType('Success');
        }else{
            setShow(true);
            setUploadError(res.message);
            setShowType('Error');
        }
        return;
    }

    function getEstTemplates(){
        let estTemplates = allTemplates.map((template)=>({
            label: template.name,
            value: template.template_id
        }));

        estTemplates.unshift({
            label: "None",
            value: 0
        });

        return estTemplates;
    }

    useEffect(()=>{
        fetchTemplates();
        consoleLog('handleSearchInput useEffect>>>');
        getADTemplates();
        fetchAllTemplates();
    }, [selectedValue,pageOffset]);

    return (
        <div>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs | Template Manager</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
             {
                uploadError ?
                <ToastBox
                setShow={setShow}
                show={show}
                Text={uploadError}
                Type={showType}
                />
                :null
            }
            <Menu page={page} selectType={selectType}/>
            <ContentContainer>

                <div className="first_left_template">
                    <p className="title">Template Manager</p>

                    <div className="custom-width-select">
                        <label>Template Type</label>
                        <Select
                            placeholder={<div>Select Template</div>}
                            value={optionsx.find(obj => obj.value == selectedValue)}
                            options={optionsx}
                            components={{DropdownIndicator }}
                            onChange={handleChangeSelect}
                            className="w-75 bg-transparent select-shade-items"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    boxShadow: "none",
                                    border:  "1px solid #B4BEBF !important"
                                  }),
                                  menu: (provided, state) => ({
                                    ...provided,
                                    border:"1px solid #B4BEBF",
                                    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.3)",
                                    border:  "1px solid #B4BEBF !important"
                                  }),
                                  placeholder:(provided, state) => ({
                                      ...provided,
                                      color:'#B4BEBF',
                                      fontStyle:'italic'
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused && "#ffffff",
                                    color: "#737F82",
                                 })
                            }}
                            isSearchable={false}
                        />
                    </div>
                    <div class="search">
                        <div className="custom-width-select margintop-20">
                            <label>Keyword Search</label>
                            <input
                            type="text"
                            className={'w-75 search-custom-input'}
                            placeholder="Enter keyword (template name, email, etc.)"
                            onChange={handleSearchInput}
                            value={inputSearch}
                            />
                        </div>
                        { loading ?
                            <LoadingContainer>
                                <img className="loader" src={Loader}/>
                            </LoadingContainer>
                            :
                            <Template>
                                <div className="btn" onClick={() => {navigate("/newTemplate")}}>
                                    <Link className="w-100 noDec" to="/newTemplate">Create new Template</Link>
                                </div>

                                <label className="btn">
                                    Upload Template
                                    <input type="file" className="w-100 noDec" onChange={handleChangeJSON} />
                                    {/* <a onClick={UploadTemplateData} className="w-100 noDec">Upload New template</a> */}
                                </label>
                            </Template>
                        }
                    </div>
                    
                </div>


           <div>
                { loading ?
                    <LoadingContainer>
                        <img className="loader" src={Loader}/>
                    </LoadingContainer>
                :
                    <>
                        { templates.length < 1 ?
                            <>
                                <div className="second_left_template pb-0">
                                    <p className="title">My Templates</p>

                                    <Alert variant="success" style={{opacity:'1', background:'#D3F8EE', fontFamily:'Roboto'}}>
                                        <p style={{fontSize:'14px', fontFamily:'Roboto', color:'#F7941C', fontWeight: 'bold' }}> No template found.</p>
                                    </Alert>
                                </div>
                            </>
                        :
                            <>
                            <EstimateTemplateTable
                                setPageOffset={setPageOffset}
                                totalRecords={totalRecords}
                                itemsPerPage={itemsPerPage}
                                fetchTemplates={fetchTemplates}
                                templates={templates}
                                estTemplate={getEstTemplates()}
                                loading={loading}
                                setLoading={setLoading}
                                setTemplates={setTemplates}
                                docTypeId={selectedValue}
                                aDTemplates={aDTemplates}
                                setaDTemplates={setaDTemplates}
                                teamRole={teamRole}
                                userID={userID}
                                forcedPage={forcedPage}
                                setForcedPage={setForcedPage}
                            />
                            </>
                        }
                    </>
                }
           </div>
            
            </ContentContainer>

            <Footer/>
        </div>
    )
}

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
    }
`

const ContentContainer = styled.div`
    .search {
        display: flex;
        margin-right: 0;
        margin-left: 0;
    }
    min-height:85vh;
    width:100%;
    background:#F7F9FA;
    padding:0 10%;

    @media(max-width:600px){
                padding: 0 5%;
            }

    .first_left_template{
        width:100%;
        padding:50px 0 0 0;

        .title{
            font-size:28px;
            color:#212E33;
            font-family:Roboto;
            margin:0px 0 33px 0;
            letter-spacing:-1.24px;
        }
        .css-45l6q1-menu {
            div:nth-child(1) {
                border-bottom: 1px solid rgba(0,0,0,0.4);
            }
        }
        
        .css-45l6q1-menu {
            div {
                color:#212E33;
            }
        }

        .css-45l6q1-menu {
            div {
                div:hover {
                    background-color: #F7F9FA;
                }
            }
        }

        .custom-width-select{
            width:30%;

            @media(max-width:600px){
                width:90%;
            }
        }
        .margintop-20{
            margin-top:20px;
            display: flex;
            flex-direction: column;
        }
        .search-custom-input{
            height: 38px;
            outline: none;
            padding: 10px;
            color: #212e33;
            font-style: normal;
            border-radius: 4px;
            border: 1px solid #b4bebf;
            font-style: italic;
          }
        }
    }
    .second_left_template{
        width:100%;
        padding:70px 0 65px 0;

        .title{
            font-size:24px;
            color:#212E33;
            font-family:Roboto;
            margin:0px 0 22px 0;
            letter-spacing:-1.24px;
        }

        @media(max-width:768px){
            padding:30px 0 65px 0;
        }
    }

    .third_left_template{
        width:100%;
        padding:65px 0 18px 0;

        @media(max-width: 768px) {
            padding:20px 0 15px 0;
        }

        .title{
            font-size:24px;
            color:#212E33;
            font-family:Roboto;
            margin:0px 0 15px 0;
            letter-spacing:-1.24px; 

            @media(max-width: 768px) {
                font-size: 20px;
            }
        }
        .desc{
            font-size:14px;
            color:#212E33;
            font-family:Roboto;
            font-weight:500;
            margin-bottom:26px;
        }
    }


`

const Title = styled.div`
    height:20vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;

    p{
        font-size:36px;
        font-weight:500;
    }
`

const TabContent = styled.div`
    height:100px;
    width:100%;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
    padding-left:15px;
    .cTitle{
        font-size:22px;
        font-weight:500;
        text-transform:uppercase;
    }
`

export const Template = styled.div`
    min-height:100px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    padding:0px 15px 0px 0;

    @media(max-width:1080px){
        padding:0px 15px 0px 50px;
    }

    @media(max-width:768px){
        padding:25px 0;
        flex-direction:column;
    }
    input[type="file"] {
        display: none;
    }
    .modal-content{
        margin-top: 20px !important;
    }
    .btn{
        margin-top: 20px;
        width:210px;
        height:44px;
        display:flex;
        align-items:center;
        justify-content:center;
        color:#212E33;
        font-family:Rajdhani;

        text-transform:uppercase;
        border-radius:3px;
        background:#F7941C;
        font-size:16px;
        font-weight:500;
        text-decoration:none;
        &:hover{
            background-color: #000;
            color: #fff;
        }
        &:hover .noDec{
            color: #fff;
        }
        & .noDec{
            text-decoration:none !important;
            color:#212E33;
        }
    }
`
